<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import chat from "@/views/pages/chat/chat.vue";

/**
 * Profile component
 */
export default {
  components: { Layout, PageHeader, chat },
  page: {
    title: "Tickets",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  mounted() {
 
  },
  data() {
    return {
      title: "Tickets",
      items: [
        {
          text: "Tickets",
        },
        {
          text: "Tickets",
          active: true,
        },
      ],
    };
  },
  methods: {

    
  },
};

</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <chat :user="null"></chat>
    <!-- end row -->
  </Layout>
</template>
